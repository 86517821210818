import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Accordion = makeShortcode("Accordion");
const List = makeShortcode("List");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "teaching-tokens",
      "style": {
        "position": "relative"
      }
    }}>{`Teaching Tokens`}<a parentName="h1" {...{
        "href": "#teaching-tokens",
        "aria-label": "teaching tokens permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p>{`Simplicity is worth more`}<br parentName="p"></br>{`
`}{`than any token amount`}<br parentName="p"></br>{`
`}{`you might magic out of`}<br parentName="p"></br>{`
`}{`an imaginary ability`}<br parentName="p"></br>{`
`}{`to model reality.  `}</p>
      </Box>
    </InfoBlock>
    <p>{`Tokens can be tricky to understand `}<strong parentName="p">{`because the way people use them influences how useful they are`}</strong>{`. We call this a "feedback loop". Designing a token is not only a computer science or economic endeavour. It also includes psychology, sociology, cultural anthropology, literature, and advertising. All that said: you don't need be an expert to start playing with token designs today. Indeed, no-one can be an expert in all these diverse fields at once. What matters most - much like `}<a parentName="p" {...{
        "href": "/learn/module-7/the-gift/#what-can-we-do-with-all-this"
      }}>{`the gift`}</a>{` - is that `}<strong parentName="p">{`you are aware`}</strong>{` of the scope of the fields in which you're playing. Most importantly:`}</p>
    <InfoBlock mdxType="InfoBlock">
      <p><strong parentName="p">{`It is the feedback loop that is complex, not the token itself`}</strong>{`. `}</p>
    </InfoBlock>
    <p>{`This is the first litmus test we can offer you. Understanding how a token works in terms of the code which creates and directs it ought to be possible in a few hours of dedicated study. If this is not the case, it is likely that the designers either (i) don't fully understand their own work, (ii) are trying to appear more intelligent than they are, or (iii) are purposefully trying to hide something.`}</p>
    <p>{`Moreover, if a token designer tells you what the `}<em parentName="p">{`effect`}</em>{` of deploying a token in the world will be, and assures that you that they "know" how and why it will unfold as they predict, you can also be sure that they have not fully understood the domain in which they are working. This is not a slight on anyone's intelligence. Feedback loops create "complex systems", whose behavior is `}<em parentName="p">{`intrinsically difficult to model`}</em>{` due to the dependencies and relationships between their parts, and between the system and its environment.`}</p>
    <blockquote>
      <p parentName="blockquote">{`💡 Tokens are economic code which create psycho-social cultural systems that both depend upon and create narratives of use.`}</p>
    </blockquote>
    <p>{`With that established, we can begin this section with an exploration of what not to do when designing tokens. This `}<a parentName="p" {...{
        "href": "/learn/module-1/value"
      }}>{`via negativa`}</a>{` approach is one we commonly employ in Kernel when beginning to learn about a new and potentially complex domain.`}</p>
    <h2 {...{
      "id": "the-cost-of-decisions",
      "style": {
        "position": "relative"
      }
    }}>{`The Cost Of Decisions`}<a parentName="h2" {...{
        "href": "#the-cost-of-decisions",
        "aria-label": "the cost of decisions permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Using assets to govern others merges the worst of capitalism with tyranny.`}</strong></p>
    </blockquote>
    <p>{`The above is true when you do not need to spend your asset to exercise governance rights. Taking snapshots or tallies literally dissociates `}<a parentName="p" {...{
        "href": "/learn/module-2/money-speech/"
      }}>{`meaningful speech from the movement of economic value`}</a>{` which is the primary mechanism by which blockchains might serve as (pro)social media.`}</p>
    <p>{`Kernel's position is premised on `}<a parentName="p" {...{
        "href": "/learn/module-4/consensus/#funded-code-running-consensus"
      }}>{`economic code running consensus`}</a>{`. It's radical: forget quora, participation, and all the other “nice” social outcomes people are trying to design for with ill-conceived tools that disassemble a potentially prosocial aspect of blockchains by allowing you to vote without spending your tokens. If you insist on programming your token to be an asset given the psychological pull this exerts over most people, then this is our advice:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Any governance proposal must specify the budget needed to fulfill itself. No quorum is necessary: all that is required to begin work is enough “votes”, in the form of valuable tokens, `}<strong parentName="p">{`actually being sent to the proposal in order to fund work`}</strong>{`.`}</p>
    </blockquote>
    <p>{`This is the promise of decentralized organisation: we don't need a single person or group to decide what we work on. We work on whatever we can fund, and we craft the incentive structures such that those with the perspective and/or experience and/or skills to do the work tend to accrue the funds to direct ongoing work in a transparent fashion. `}</p>
    <p><strong parentName="p">{`The cost to make a decision must be directed to those responsible for enacting the decision`}</strong>{`. This is the best way to align incentives in any organisation over the long term.`}</p>
    <p>{`If governing the system requires spending tokens, then governance becomes the kind of `}<a parentName="p" {...{
        "href": "https://vitalik.eth.limo/general/2017/10/17/moe.html"
      }}>{`token sink`}</a>{` required to ensure equitable wealth distribution in the long term. Requiring that tokens literally fund decisions creates a profound feedback loop: value accrues (or dissipates) as an effect of what we collectively choose to spend our tokens on.`}</p>
    <p><strong parentName="p">{`Proposals are not about permission`}</strong>{` and so `}<strong parentName="p">{`governance must be the literal means by which distribution of funds happens`}</strong>{`, rather than funds flowing `}<em parentName="p">{`after`}</em>{` a decision has been taken. `}</p>
    <p>{`Well-funded development of mutually helpful software is the outcome; governance is the means by which tokens are distributed by anyone who owns them to signal their support meaningfully. This doesn't preclude innovative bootstrapping programmes like quadratic matching from shared funding pools or communal treasuries, but it does bind economic value to meaningful speech, which is the primary manner in which we can make more (literally) effective collective decisions.`}</p>
    <p><a parentName="p" {...{
        "href": "/learn/module-6/serenity/#a-defenders-game"
      }}>{`Defender's advantage and thinking in terms of penalties`}</a>{` is critical. However, this way of costing decisions illuminates how well-designed tokens take what is superficially a “penalty” - i.e. spending your money to support a proposal you think is significant - and turn it into the very (rewarding) act which ensures that the tokens you retain become more valuable. `}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`The penalty is the reward when we build governance systems that distribute funds by virtue of how they work, rather than as the outcome of their work.`}</strong></p>
    </blockquote>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`Won't people with the most tokens get to dictate work?`}</strong></p>
      <p>{`If we create more prosocial and less tyrannical forms of governance, tokens are valuable to the extent that they are used fund mutually beneficial work (so, if you have a lot of tokens, you're incentivized to spend a majority funding others - consider Joe Lubin and Consensys in the early days of Ethereum). Also, you can program your incentives any way you like (consider `}<a parentName="p" {...{
          "href": "/tokens/token-studies/dap-ps/"
        }}>{`dap.ps`}</a>{`, which explicitly makes it cheaper to vote on projects that have more initial resources). `}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`How does any token get its initial value?`}</strong></p>
      <p>{`By being brought to life by and in a community whose members already trust each other to transact with it, and by employing quadratic fund matching (and other methods) from shared communal treasuries, if possible.`}</p>
    </Accordion>
    <h2 {...{
      "id": "references",
      "style": {
        "position": "relative"
      }
    }}>{`References`}<a parentName="h2" {...{
        "href": "#references",
        "aria-label": "references permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`As always, we recommend you read more of Vitalik's blog if this is a topic you want to understand further.`}</p>
    <List mdxType="List">
      <Link to="https://ethresear.ch/t/votes-as-buy-orders-a-new-type-of-hybrid-coin-voting-futarchy/10305" mdxType="Link">
        <p>{`Votes as buy orders`}</p>
      </Link>
      <Link to="https://vitalik.eth.limo/general/2021/08/22/prices.html" mdxType="Link">
        <p>{`Prices`}</p>
      </Link>
      <Link to="https://vitalik.eth.limo/general/2021/08/16/voting3.html" mdxType="Link">
        <p>{`Moving beyond coin voting governance `}</p>
      </Link>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      